@import url(https://use.fontawesome.com/releases/v5.8.1/css/all.css);
* {
    box-sizing: border-box;
}

body, .app {
    margin: 0;
    padding: 0;
    background: rgb(61,46,37);
    background: -webkit-radial-gradient(circle, 
                                rgba(61,46,37,1) 0%, 
                                rgba(37,28,22,1) 50%, 
                                rgba(14,10,8,1) 100%);
    background: radial-gradient(circle, 
                                rgba(61,46,37,1) 0%, 
                                rgba(37,28,22,1) 50%, 
                                rgba(14,10,8,1) 100%);
}

body, .app, textarea {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
                "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
                sans-serif;
}

.schedule-page {
    margin: 0 auto;
    height: 100vh;
    width: 100vw;
    max-width: 500px;
    min-width: 300px;
}

/*--- Date Picker ---*/

.date-display, .date-select {
    padding: 0.6em;
}

.date-header {
    position: fixed;
    width: 100%;
    max-width: 500px;
    min-width: 300px;
    margin: 0 auto;
    flex: 0 0 auto;
}

.date-header .date-display {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    background: rgb(87,62,48);
    background: -webkit-gradient(linear, 
                                left bottom, left top, 
                                from(rgba(87,62,48,1)), 
                                color-stop(20%, #745745), 
                                color-stop(80%, #745745), 
                                to(rgba(87,62,48,1)));
    background: -webkit-linear-gradient(bottom, 
                                rgba(87,62,48,1) 0%, 
                                #745745 20%, 
                                #745745 80%, 
                                rgba(87,62,48,1) 100%);
    background: linear-gradient(0deg, 
                                rgba(87,62,48,1) 0%, 
                                #745745 20%, 
                                #745745 80%, 
                                rgba(87,62,48,1) 100%);
    color: white;
    font-weight: bold;
}

.date-header .date-select {
    background: #ffe87c;
    height: 1px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    margin: 0 auto;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    background: white;
    background: -webkit-radial-gradient(circle, 
                                rgba(255,255,255,1) 80%, 
                                rgba(242,236,232,1) 100%);
    background: radial-gradient(circle, 
                                rgba(255,255,255,1) 80%, 
                                rgba(242,236,232,1) 100%);
}
.date-header .date-select.extended {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    height: auto;
}

.drop-down-arrow {
    padding: 0 0.5em;
    -webkit-transform: translateY(0.2em);
            transform: translateY(0.2em);
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}
.extended .drop-down-arrow {
    -webkit-transform: rotateZ(-180deg);
            transform: rotateZ(-180deg);
}

/*--- /Date Picker ---*/


/*--- Agenda ---*/

.agenda {
    flex: 1 1 auto;
    height: 100vh;
    padding: 2em;
    padding-top: 4.5em;
    overflow-y: scroll;
    background: rgb(187,187,187);
    background: -webkit-radial-gradient(circle, 
                                rgba(187,187,187,1) 60%, 
                                rgba(177,169,164,1) 100%);
    background: radial-gradient(circle, 
                                rgba(187,187,187,1) 60%, 
                                rgba(177,169,164,1) 100%);
}

.add-agenda-item, .agenda-item {
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 1em;
    background: white;
    background: -webkit-radial-gradient(circle, 
                                rgba(255,255,255,1) 80%, 
                                rgba(242,236,232,1) 100%);
    background: radial-gradient(circle, 
                                rgba(255,255,255,1) 80%, 
                                rgba(242,236,232,1) 100%);
}

.add-agenda-item {
    align-items: center;
    height: 2.8em;
}

.add-plus {
    font-size: 2em;
    font-weight: bold;
    padding-bottom: 0.2em;
    padding-left: 0.08em;
}

.agenda-item {
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 1.5em;
    padding-top: 0.25em;
}

.agenda-item > * {
    margin-top: 0.5em;
}

.agenda-item > .description {
    flex-basis: 100%;
}

p.input-label {
    margin: 0;
    font-size: 0.7em;
}

.start-time, .end-time {
    display: inline-block;
    flex-basis: 45%;
}

.time-input, .description-input {
    width: 100%;
}

.done-button, .delete-button, .edit-button {
    height: 1.5em;
    margin-top: 1em;
    background: silver;
    background: -webkit-gradient(linear, 
                                left bottom, left top, 
                                from(silver),
                                to(#cccccc));
    background: -webkit-linear-gradient(bottom, 
                                silver 0%,
                                #cccccc 100%);
    background: linear-gradient(0deg, 
                                silver 0%,
                                #cccccc 100%);
    border: 1px solid #969696;
    border-radius: 2px;
}

.delete-button {
    background: #db5c5c;
    background: -webkit-gradient(linear, 
                                left bottom, left top, 
                                from(#db5c5c),
                                to(#e77676));
    background: -webkit-linear-gradient(bottom, 
                                #db5c5c 0%,
                                #e77676 100%);
    background: linear-gradient(0deg, 
                                #db5c5c 0%,
                                #e77676 100%);
    border: 1px solid #b15252;
}

/*--- /Agenda ---*/
